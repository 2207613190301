<template>
  <div class="content">
    <div class="accountCon">
      <div class="btnBox">
        <div></div>
        <el-button type="primary" class="mb10" round size="mini" @click="tapBtn('')">添加会员</el-button>
      </div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn label="等级名称" prop="name" />
            <ElTableColumn label="最小值" prop="minimum" />
            <ElTableColumn label="最大值" prop="maximum" />
            <ElTableColumn label="备注" prop="remarks" />
            <ElTableColumn label="创建时间" prop="create_time" />
            <ElTableColumn label="最后编辑时间" prop="update_time" />
            <ElTableColumn label="操作" fixed="right">
              <template slot-scope="{ row }">
                <span class="theme" @click="tapBtn(row.id)">编 辑</span>
              </template>
            </ElTableColumn>
          </el-table>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getGradeList } from "@/api/client";
export default {
  name: "MemberRank",
  data() {
    return {
      list: [] // 数据展示
    };
  },

  mounted() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList(option) {
      getGradeList().then(res => {
        this.list = res.list;
      });
    },
    // 点击详情
    tapBtn(id) {
      this.$router.push("./addRank?id=" + id);
    }
  }
};
</script>

<style lang="scss" scoped>
.mainColor {
  color: #2dc0a3;
}
.content {
  height: calc(100% - 56px);
  overflow-y: auto;
}
.bgW {
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;
}

.el-form-item {
  margin: 5px 40px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 0px 10px 80px;
  .btnBox {
    display: flex;
    justify-content: space-between;
  }
}
</style>
